/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useCallback } from "react"
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { isEmpty, replace } from 'lodash';
import { siteURL } from '../../client-config';
import axios from 'axios';

const userIdCookie = 'openweb-gt-userId'


function SEO({ facebookDomainVerification, description, lang, meta, title, header: { siteTitle }, seoData, uri, openGraphImage }) {



	function generateRandomString(length) {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return `openweb-ga-userId-${result}`;
	}

	function getCookie(name) {
		const value = `; ${document?.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
	}

	const getUserId = useCallback(() => {
		let userId = getCookie(`${userIdCookie}`) ? getCookie(`${userIdCookie}`) : false;

		if (!userId) {
			userId = generateRandomString(30);
			const expirationDate = new Date();
			expirationDate.setTime(expirationDate.getTime() + (60 * 60 * 1000 * 365));
			document.cookie = `${userIdCookie}=${userId}; expires=${expirationDate.toUTCString()}`;
		}
		return userId;
	}, [])

	const setUserId = useCallback(() => {
		if (window && window?.dataLayer !== undefined) {
			window.dataLayer.push({
				"event": 'userData',
				"user_id": getUserId()
			});
		}
	}, [getUserId])

	useEffect(() => {
		setUserId();
	}, [setUserId]);


	const { site, OWGraphQL: { seo } } = useStaticQuery(
		graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                        fbAppId
                    }
                }
                OWGraphQL {
                    seo {
                        webmaster {
                            googleVerify
                        }
                        social {
                            facebook {
                                defaultImage {
                                    sourceUrl
                                }
                            }
                        }
                    }
                }
            }
		`
	);

	if (isEmpty(seoData)) {
		seoData = {
			title: '',
			metaDesc: '',
			// facebook
			opengraphTitle: '',
			opengraphDescription: '',
			opengraphImage: {
				sourceUrl: ''
			},
			// twitter
			twitterTitle: '',
			twitterDescription: '',
			twitterImage: {
				sourceUrl: ''
			}
		};
	}



	if (!isEmpty(seoData) && isEmpty(seoData.title)) {
		seoData.title = '';
	}

	if (!isEmpty(seoData) && isEmpty(seoData.metaDesc)) {
		seoData.metaDesc = '';
	}

	if (!isEmpty(seoData) && isEmpty(seoData.opengraphDescription)) {
		seoData.opengraphDescription = '';
	}

	if (!isEmpty(seoData) && isEmpty(seoData.twitterDescription)) {
		seoData.twitterDescription = '';
	}

	if (!isEmpty(seoData) && isEmpty(seoData.opengraphTitle)) {
		seoData.opengraphTitle = '';
	}

	if (!isEmpty(seoData) && isEmpty(seoData.twitterTitle)) {
		seoData.twitterTitle = '';
	}

	if (!isEmpty(seoData) && (isEmpty(seoData.opengraphImage) || isEmpty(seoData.opengraphImage.sourceUrl))) {
		seoData.opengraphImage = {
			sourceUrl: ''
		};
	}

	if (!isEmpty(seoData) && (isEmpty(seoData.twitterImage) || isEmpty(seoData.twitterImage.sourceUrl))) {
		seoData.twitterImage = {
			sourceUrl: ''
		};
	}

	if (!isEmpty(seoData) && (isEmpty(seoData.schema) || isEmpty(seoData.schema.raw))) {
		seoData.schema = {
			raw: ''
		};
	}

	if (!isEmpty(seoData.schema.raw) && !isEmpty(siteURL) && !isEmpty(process.env.GATSBY_SITE_URL)) {
		seoData.schema.raw = replace(seoData.schema.raw, new RegExp(siteURL, 'g'), process.env.GATSBY_SITE_URL)
	}

	const seoTitle = seoData.title || title;

	const ogImage = seoData?.opengraphImage?.sourceUrl || '';

	const seoDescription = seoData.metaDesc || site?.siteMetadata?.description || description;
	const ogTitle = `${seoData.opengraphTitle}` || seoTitle;
	const ogDesc = seoData.opengraphDescription || seoDescription;
	const opengraphImage = seoData?.opengraphImage?.sourceUrl || ogImage;

	// Twitter
	const twitterDesc = seoData.twitterDescription || seoDescription;
	const twitterTitle = seoData.twitterTitle || seoTitle;
	const twitterImage = seoData?.twitterImage?.sourceUrl || ogImage;

	const googleVerify = seo.webmaster.googleVerify || '';
	const ogURL = uri === "/home-page/" ? `${site.siteMetadata.siteUrl}` : `${site.siteMetadata.siteUrl}${uri}`;
	const fbAppId = site.siteMetadata.fbAppId;
	const canonical = uri === "/home-page/" ? `${site.siteMetadata.siteUrl}` : `${site.siteMetadata.siteUrl}${uri}`;
	const robotsFollow = seoData.metaRobotsNofollow;
	const robotsIndex = 'follow' === robotsFollow ? 'index' : 'noindex';
	const seoSchema = seoData.schema.raw;



	const metaArray = [
		{
			name: `title`,
			content: seoTitle,
		},
		{
			name: `description`,
			content: seoDescription,
		},
		{
			property: `og:locale`,
			content: `en_US`,
		},
		{
			property: `og:url`,
			content: ogURL,
		},
		{
			property: `og:image`,
			content: opengraphImage,
		},
		{
			property: `og:site_name`,
			content: `OpenWeb`,
		},
		{
			property: `og:title`,
			content: ogTitle,
		},
		{
			property: `og:description`,
			content: ogDesc,
		},
		{
			property: `og:type`,
			content: `website`,
		},
		{
			name: `twitter:card`,
			content: `summary_large_image`,
		},
		{
			name: `twitter:creator`,
			content: site.siteMetadata.author,
		},
		{
			name: `twitter:title`,
			content: twitterTitle,
		},
		{
			name: `twitter:description`,
			content: twitterDesc,
		},
		{
			name: `twitter:image`,
			content: twitterImage
		},
		{
			name: `robots`,
			content: `${robotsIndex}, ${robotsFollow}`,
		},
		{
			name: `google-site-verification`,
			content: googleVerify,
		},
		{
			property: `fb:app_id`,
			content: fbAppId,
		},
	];

	if (uri === "/home-page/") {
		metaArray.push({
			property: 'facebook-domain-verification',
			content: '4yomxjd38xzyrg87pzfa7weqiqhxoa'
		});
	}


	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={seoTitle}
			titleTemplate={`%s`}
			link={
				canonical
					? [
						{
							rel: "canonical",
							href: canonical,
						},
					]
					: []
			}
			meta={metaArray.concat(meta)}
		>
			{seoSchema &&
				<script type="application/ld+json">
					{seoSchema}
				</script>
			}
		</Helmet>
	);
}

SEO.defaultProps = {
	description: ``,
	lang: `en`,
	meta: [],
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
	header: PropTypes.object,
};

export default SEO;