/**
 * External dependencies.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import classnames from 'classnames';
import { Link } from 'gatsby';


/**
 * Internal dependencies.
 */
import DropdownIcon from '../../icons/dropdown-icon';
import { sanitize } from '../../../utils/functions';

import './style.scss';

const SLUGS = {
	RESOURCES: '/resources/',
	CASE_STUDIES: '/resources/case-studies',
	WEBINARS: '/resources/webinars',
	GUIDES: '/resources/guides'
}

const PAGES = [
	SLUGS.CASE_STUDIES,
	SLUGS.WEBINARS,
	SLUGS.GUIDES
]

const NAMES = {
	[SLUGS.RESOURCES]: 'All Resources',
	[SLUGS.CASE_STUDIES]: 'Case Studies',
	[SLUGS.WEBINARS]: 'Webinars',
	[SLUGS.GUIDES]: 'Guides'
}

/**
 * Resources Banner Component.
 *
 * @param {Object} page Page props.
 *
 * @return {jsx}
 */
const ResourcesBanner = ({ page, currentPageSlug }) => {

	const { title } = page;
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const listClassNames = classnames(
		'blog-header-terms',
		{
			'blog-header-terms--menu-open': isMenuOpen
		}
	);

	// console.log(currentPageSlug === SLUGS.RESOURCES)

	const allArticlesClassNamesMobile = classnames(
		'blog-header__term-item',
		'hide-for-large',
		'blog-header__term-item--all-articles',
		{ 'blog-header__term-item--active': currentPageSlug == SLUGS.RESOURCES }
	);

	const allArticlesClassNamesDesktop = classnames(
		'blog-header__term-item',
		'show-for-large',
		'blog-header__term-item--all-articles',
		{ 'blog-header__term-item--active': currentPageSlug == SLUGS.RESOURCES }
	);
	// console.log(SLUGS.RESOURCES);
	// console.log('currentPageSlug', currentPageSlug);
	// console.log('currentPageSlug == SLUGS.RESOURCES', currentPageSlug == SLUGS.RESOURCES)
	// console.log('allArticlesClassNamesDesktop', allArticlesClassNamesDesktop);



	return (
		<section className="resources-sections resources-banner">

			<div className="blog-header">
				<div className="row">
					<div className="small-12 columns">
						<h1 className="blog-header__title" dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
					</div>
				</div>
				<ul className={listClassNames}>
					<li className={allArticlesClassNamesMobile}>
						<Link to={'/resources'} className="blog-header__term-link blog-header__term-link--all-articles">All resources</Link>
						<button onClick={() => setIsMenuOpen(!isMenuOpen)} className="blog-header__drop-down-button icon-button"><DropdownIcon /></button>
					</li>
					<ul className="blog-header__term-items">
						<li className={allArticlesClassNamesDesktop}>
							<Link className="blog-header__term-link blog-header__term-link--all-articles" to={`/resources`}>All resources</Link>
						</li>
						{PAGES.map((page_item, index) => {
							const liClassNames = classnames('blog-header__term-item', { 'blog-header__term-item--active': page_item == currentPageSlug });
							return (6 > index) ? (
								<li className={liClassNames} key={`respurce-page-${index}`}>
									<Link className="blog-header__term-link" to={`${page_item}`}>{NAMES[page_item]}</Link>
								</li>
							) : null;
						})}
					</ul>
				</ul>
			</div>
		</section>
	);

};

ResourcesBanner.propTypes = {
	page: PropTypes.object,
};

ResourcesBanner.defaultProps = {
	page: {
		title: '',
		excerpt: ''
	}
};

export default ResourcesBanner;
