/**
 * External dependencies.
 */
import React, { createRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import ResourcesBanner from '../../components/resources/banner';
// import ResourcesArticles from '../../components/resources/resources-articles';
// import OverviewGuides from '../../components/resources/overview-guides';
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";
import RecourcesList from '../../components/resources/resources-list/resources-list';
import ContactForm from '@blocks/contact-form';

/**
 * Resources Template Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */


const Resources = (props) => {
	const { path, pageContext: { page, page: { uri, seo }, resources } } = props;
	// console.log(props)
	const openGraphImage = getOgImage(seo);
	const [currentPage, setCurrentPage] = useState(1);
	const [items, setItems] = useState([]);
	const [size, setSize] = useState(6);
	const [lastItemsCount, setLastItemsCount] = useState(0);

	const ref = createRef();
	const refIndex = lastItemsCount ? (items.length - lastItemsCount) : 0;
	const perPage = currentPage > 1 ? 5 : 6;

	const isFirstSet = (currentPage === 1);

	let currentType = false;
	if (path.includes('case-studies')) { currentType = 'case-study'; }
	if (path.includes('webinar')) { currentType = 'webinar'; }
	if (path.includes('guides')) { currentType = 'guides'; }


	useEffect(() => {
		let fillItems = [];
		if (resources && resources.length) {
			fillItems = [...resources];
		}
		setItems([...fillItems]);
	}, []);

	const handleSize = () => {
		setSize(size + 5);
	}


	return (
		<Layout title="Resources" uri={uri} className="resources-page">
			<SEO
				title={page.title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<ResourcesBanner
				page={page}
				currentPageSlug={path}
			/>
			<RecourcesList
				currentType={currentType}
				size={size}
				items={items}
				animate={isFirstSet}
				ref={ref}
				refIndex={refIndex}
				handleLoadMore={handleSize}
			/>
			<ContactForm />
		</Layout>
	)
};

Resources.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default Resources;
