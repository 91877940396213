/**
 * External dependencies.
 */
import React from 'react';

/**
 * ArrowBigRightRecources Component.
 *
 * @param {string} color Color Hex code.
 *
 * @return {jsx}
 */
const ArrowBigRightRecources = (color) => (
    <svg width="128" height="17" viewBox="0 0 128 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.529732 9H127.211M127.211 9L118.665 1M127.211 9L118.665 16.5" stroke={color} />
    </svg>
);

export default ArrowBigRightRecources;
