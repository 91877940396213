/**
 * External dependencies.
 */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { Link } from 'gatsby';

/**
 * Internal dependencies.
 */
// import ArticleCard from '../article-card';
import { webinarSlug } from '../../../utils/functions';
import ResourceArticle from '../article/index';

import './style.scss';

/**
 * RecourcesList Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const RecourcesList = forwardRef(({ handleLoadMore, items, currentType, size, animate = true, className, refIndex }, ref) => {

    if (isEmpty(items) || !items.length) {
        return null;
    }

    const containerClass = classnames('recources-list', 'blog-list', className);

    const filteredList = (items) => items.filter((item) => {
        if (currentType) {
            return item.contentTypeName === currentType;
        }
        return item;
    });

    return (
        <>
            <div className={containerClass}>
                {filteredList(items).slice(0, size).map((item, index) => {
                    const isWide = 0 == index % 5;
                    const listClass = classnames('blog-list__item', { 'blog-list__item--wide': isWide });
                    const linkClass = "blog-list__card-link";
                    let CurrentTag = ({ children }) => (<Link className={linkClass} to={item.uri && webinarSlug(item.uri)} >{children}</Link>)
                    if (item?.custom_fields?.guideDetailsGroup?.downloadLink?.url) {
                        const { url, target, title } = item.custom_fields.guideDetailsGroup.downloadLink;
                        CurrentTag = ({ children }) => (<a className={linkClass} target={target} href={url} title={title} >{children}</a>)
                    }

                    return (
                        <div ref={index === refIndex ? ref : null} key={`${item.id}-${index}`} className={listClass}>
                            <CurrentTag >
                                <ResourceArticle
                                    data={item}
                                    type={item.contentTypeName}
                                    isWide={isWide}
                                    animate={animate}
                                />
                            </CurrentTag>
                        </div>
                    );
                })}
            </div>

            {filteredList(items).length > size && <div onClick={() => handleLoadMore()} className="load-more"><button className="load-more__button icon-button">Load more</button></div>}
        </>
    );
});

RecourcesList.propTypes = {
    items: PropTypes.array,
    animate: PropTypes.bool,
    className: PropTypes.string,
    refIndex: PropTypes.number
};

RecourcesList.defaultProps = {
    items: [],
    animate: true
};

export default RecourcesList;
